import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import get from "lodash-es/get";
import { useIntl, FormattedMessage, Link } from "gatsby-plugin-intl";
import Layout from "../components/layout";
import SEO from "../components/seo";

const WorkPage = ({ data }) => {
  const intl = useIntl();
  const allProjects = get(data, "allContentfulProject.nodes", []);
  const orderedProjects = get(
    data,
    "allContentfulOrderedProjects.nodes[0].projects",
    []
  );
  const projects = orderedProjects.length ? orderedProjects : allProjects;

  return (
    <Layout>
      <SEO
        title={`${intl.formatMessage({
          id: "navbar.projects",
          defaultMessage: "Projects",
        })} | Younics`}
      />

      <div className="container pt-32 md:pt-52">
        <h1 className="text-5xl md:text-7xl font-600 leading-none pb-8">
          <FormattedMessage id="projects.title" defaultMessage="Our Work" />
        </h1>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-7 xl:gap-9">
          {projects.map((project) => (
            <Link
              to={`/projects/${project.slug}`}
              key={project.id}
              className="group"
            >
              <div className="relative rounded-sm overflow-hidden w-full pb-10/16 bg-gray-purple-a40 blend-lighten">
                {project.previewImage ? (
                  <div className="absolute h-full w-full transition-transform duration-300 transform origin-bottom scale-90 group-hover:scale-100">
                    <Img
                      fluid={
                        project.previewImage.localFile.childImageSharp.fluid
                      }
                      alt={
                        project.previewImage.description ||
                        project.previewImage.title
                      }
                      className="h-full w-full object-cover"
                    />
                  </div>
                ) : null}
              </div>

              <h2 className="text-xl lg:text-2xl leading-tight pt-5 md:pt-6 pb-2 md:pb-2.5 transition-colors duration-300 inline-block bg-clip-text text-white group-hover:text-transparent bg-gradient-to-r from-purple to-blue">
                {project.title}
              </h2>
              <p className="text-gray-purple leading-loose pb-6 md:pb-10 max-w-md">
                {project.excerpt.excerpt}
              </p>
            </Link>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default WorkPage;

export const pageQuery = graphql`
  fragment Project on ContentfulProject {
    id
    slug
    title
    previewImage {
      title
      description
      localFile {
        publicURL
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
    excerpt {
      excerpt
    }
  }
  query WorkQuery($locale: String) {
    allContentfulOrderedProjects(filter: { node_locale: { eq: $locale } }) {
      nodes {
        projects {
          ...Project
        }
      }
    }
    allContentfulProject(filter: { node_locale: { eq: $locale } }) {
      nodes {
        ...Project
      }
    }
  }
`;
